.BLM-sideMenu .closebtn {
    position: absolute;
    top: 0;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}
.sidebar-close {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: end;
    margin-top: 3px;
}
.Account_number {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* background-color: #d9e1ff; */
    height: 45px;
    align-items: center;
    padding: 10px;
}
.Cash_Bonus_Balance {
    /* background-color: #d9e1ff; */
    padding: 5px;
}
.hamburger {
    width: 35px;
    height: 5px;
    background-color: white;
    margin: 6px 0;
}
.hamburger_menu:hover {
    cursor: pointer;
}
.btn_hover_primary:hover {
    background-color: #003256;
}
.virtual_game_iframe,.casino_game_iframe{
    height: 80vh;
}
/* Extra small devices (phones, 600px and down) */
.virtual_game_iframe,.casino_game_iframe,.turbo_game_iframe{
    height: 80vh;
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 667px) {
    .Turbo_game, .Virtual_Games {
        width: 100% !important;
        top: 0 !important;
        bottom: 0 !important;
        left: 0 !important;
        right: 0 !important;
        margin: auto !important;
        transform: none !important;
    }
    .virtual_game_iframe, .casino_game_iframe, .turbo_game_iframe {
        height: 100vh; /* Set height to 100% of the viewport height */
    }
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .ReactModal__Content--after-open {
        width: 100% !important;
        padding: 0px!important;
    }
    .BLM-marketBox-header.BLM-accordion-header.BLM-arrowAfter{
        font-size: 10px;
    }
    .btn.BLM-btnOdds.BLM-btnOutComeOdds{
        font-size: 10px;
    }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
    .ReactModal__Content--after-open {
        width: 100% !important;
    }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
    .ReactModal__Content--after-open {
        width: 100% !important;
    }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
    .ReactModal__Content--after-open {
        width: 75% !important;
    }

}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
    .ReactModal__Content--after-open {
        width: 50% !important;
    }
    .Turbo_game,.Virtual_Games{
        width: 80% !important;
    }
}

/* Extra Extra large devices (large LED displays, 1400px and up) */
@media only screen and (min-width: 1400px) {
    .ReactModal__Content--after-open {
        width: 40% !important;
    }
    .Turbo_game{
        width: 80% !important;
    }
}

/* Extra Extra Extra large devices (large 4k displays, 1600px and up) */
@media only screen and (min-width: 1600px) {
    .ReactModal__Content--after-open {
        width: 35% !important;
    }

    .Turbo_game{
        width: 67% !important;
    }

}
@media (min-width: 701px) and (max-width: 1600px) {
    .Turbo_game,.Virtual_Games {
        inset: 53.5% auto auto 50%!important;
    }
}
.Turbo_game{
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 4px;
    outline: none;
    /*padding: 20px;*/
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 100% !important;
    /*height: 100% !important;*/
}
.Virtual_Games,.Casino_Games{
    position: absolute;
    inset: 50% auto auto 50%;
    border: 1px solid rgb(204, 204, 204);
    background: rgb(255, 255, 255);
    overflow: hidden;
    border-radius: 4px;
    outline: none;
    /*padding: 20px;*/
    margin-right: -50%;
    transform: translate(-50%, -50%);
    width: 100% !important;
    /*height: 100% !important;*/

}